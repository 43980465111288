<template>
  <div class="use-case">
    <nav-bar />
    <pricing-banner />

    <section id="pricing">
      <div class="container">
        <div class="row justify-center">
          <div class="col-10 col-sm-12 col-md-12">
            <div class="row justify-between">
              <div
                class="col-4 col-sm-11 col-md-5 d-flex"
                v-for="(price, index) in pricing"
                :key="index"
              >
                <div
                  data-aos="fade-up"
                  class="pricing-card"
                  :class="{ recommended: price.isRecommended }"
                >
                  <div class="pricing-card-title text-center">
                    <p>
                      <small>{{ price.for }}</small>
                    </p>
                    <h5>{{ price.title }}</h5>
                    <p>{{ price.tagLine }}</p>
                  </div>
                  <div class="pricing-card-body">
                    <ul>
                      <li v-for="feature in price.features" :key="feature">
                        {{ feature }}
                      </li>
                    </ul>
                  </div>
                  <div class="pricing-card-actions">
                    <a
                      href="https://calendly.com/synxlabs/30min?month=2022-09"
                      class="btn bg-light-blue"
                    >
                      {{ price.btnText }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <home-ready />
    <home-footer />
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import NavBar from "../components/NavBar.vue";
import HomeFooter from "../components/HomeFooter.vue";
import PricingBanner from "./PricingBanner.vue";
import HomeReady from "./HomeReady.vue";

export default {
  name: "Pricing",
  components: {
    NavBar,
    HomeFooter,
    PricingBanner,
    HomeReady,
  },
  data: () => {
    return {
      pricing: [
        {
          for: "FOR INDIVIDUALS",
          title: "FREE",
          tagLine: "Getting zoned in",
          features: [
            "csv records",
            "1 Collaborator",
            "10 synthetic credits",
            "1000 rows credit limit",
          ],
          btnText: "Get Started",
        },
        {
          for: "FOR INDIVIDUALS",
          title: "Premium",
          tagLine: "Getting zoned in",
          features: [
            "Basic plan",
            "Unlimited collaborator",
            "$5 / 1000 synthetic rows",
            "Unlimited Synthetic Credits",
            "Structured records (csv, txt, geoJson)",
          ],
          btnText: "Request Pricing",
          isRecommended: true,
        },
        {
          for: "FOR TEAMS",
          title: "BUSINESS",
          tagLine: "Getting zoned in",
          features: [
            "Professional plan",
            "Custom workflow",
            "24/7 technical support",
            "Unstructured records (Synthetic images & videos)",
          ],
          btnText: "Request Pricing",
        },
      ],
    };
  },
  mounted() {
    window.AOS.init();
  },
};
</script>

<style lang="scss">
#pricing {
  padding: 31px 0px;

  .pricing-card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 100%;
    padding: 45px 45px;
    border-radius: 33px;
    border: 1px solid #cecece;
    margin: 20px 5px;
    width: 100%;
    align-items: center;

    &:hover,
    &.recommended {
      border: 2px solid #cb3066;

      .pricing-card-actions {
        .btn {
          background: #cb3066 !important;
        }
      }
    }

    position: relative;
    z-index: 1;

    &.recommended {
      &:before {
        content: "RECOMMENDED";
        position: absolute;
        z-index: 3;
        top: -1px;
        left: 0;
        right: 0;
        width: 100%;
        height: 40px;
        color: #ffffff;
        background: #cb3066;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 33px 33px 0px 0px;
      }
    }

    .pricing-card-title {
      margin-top: 15px;
      margin-bottom: 60px;

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 8px;

        small {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.3);
        }
      }

      h5 {
        font-weight: 700;
        font-size: 30px;
        line-height: 22px;
        color: #0b132a;
        margin-bottom: 8px;
      }
    }

    .pricing-card-body {
      margin-bottom: 40px;
      flex: 1;

      ul {
        list-style-image: url("/images/icons/list-style-check-green.svg");
        li {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #4f5665;
          margin-left: 10px;
          margin-bottom: 12px;
        }
      }
    }

    .pricing-card-actions {
      .btn {
        height: 43px;
        width: 268px;
        max-width: 100%;
        border-radius: 5px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.03em;
      }
    }
  }

  @media (min-width: 1000px) and (max-width: 1400px) {
    .pricing-card {
      padding: 35px 10px;

      ul {
        padding-left: 20px;
      }
    }
  }

  @media (max-width: 991px) {
    .row.justify-between {
      justify-content: center;
    }

    .pricing-card {
      display: flex;
      flex-direction: column;
      padding: 45px 45px;
    }
  }
}
</style>
