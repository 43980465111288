<template>
  <div class="use-case">
    <nav-bar />
    <use-case-banner />

    <section id="cases">
      <div class="container">
        <div class="row justify-center">
          <div class="col-10 cols-sm-11 col-md-12">
            <div
              data-aos="fade-up"
              class="row case justify-between"
              v-for="(useCase, index) in useCases"
              :key="index"
            >
              <div class="col-5 col-sm-12 col-md-5 image">
                <img :src="useCase.image" alt="use case" width="400" />
              </div>
              <div class="col-6 col-sm-12 col-md-6 content">
                <h5>{{ useCase.title }}</h5>
                <p v-html="useCase.description"></p>
                <p
                  v-if="useCase.seeMore"
                  v-html="useCase.more"
                  data-aos="fade-in"
                ></p>
                <div class="actions">
                  <button class="btn" @click="seeMore(useCase)">
                    {{ useCase.seeMore ? "See less" : "See More" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <home-footer />
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import NavBar from "../components/NavBar.vue";
import HomeFooter from "../components/HomeFooter.vue";
import UseCaseBanner from "./UseCaseBanner.vue";

export default {
  name: "UseCase",
  components: {
    NavBar,
    HomeFooter,
    UseCaseBanner,
  },
  mounted() {
    window.AOS.init();
  },
  data: () => {
    return {
      useCases: [
        {
          title: "Customer Data Analytics",
          description:
            "Companies increasingly depend on market and customer analysis to support their growth strategies. To drive efficient data analytics, data teams need access to real-time, high-quality data sets. Traditional mask techniques such as data anonymization limit the ability to learn from data, removing identifying information from the original data.",
          more: "This limit the use of data to gain insights to improve decision making and growth. Synthetic data is used to protect sensitive customer data while providing similar statistical information on original data to analyze customer behavior to drive business sales growth while respecting data privacy and security rules. Synthetic data allow companies gain insights and deep understanding of customer behavior, allowing them to respond accurately to customers demand and the ability to facilitate expansion into new markets.",
          image: "/images/use-case/Customer-Data-Analytics.png",
          seeMore: false,
        },
        {
          title: "Data Sharing",
          description:
            "Sharing data with third parties is an important vehicle for business partnerships and innovation in industries. However, it is severely limited by compliance requirements because of its sensitive nature and the potential risks it entails. Synthetic data allow companies to share data with third parties, to meet the challenges of a field.",
          more: "Although it retains the same statistical properties as the original data, the synthetic data can open up new opportunities in many industries across many sectors. This can lead to better overall cost management among supply chain partners, and innovate faster, resulting in lower costs for customers and time savings in marketing new products and services.",
          image: "/images/use-case/Data-Sharing.png",
          seeMore: false,
        },
        {
          title: "Software Testing and Development",
          description:
            "Production data is sensitive and unusable or extremely difficult to extract for testing purposes. Synthetic data helps automation and acceleration and creation of higher quality datasets for testing and software development with real-time data, while greatly reducing the risk of data leakage in the test environment.",
          more: "Synthetic test data eliminates time and work related to setting up a software testing environment. You have access to high-quality datasets of any volume, optimized for your coverage needs while it maintains full data utility without any compliance risk.",
          image: "/images/use-case/Software-Testing-and-Development.png",
          seeMore: false,
        },
        {
          title: "Fraudulent Activity Identification",
          description:
            "Millions of consumers fall victim to credit card fraud every year, and the cost is transferred to financial institutions. Synthetic data helps financial services to detect the possibility of fraudulent transactions by training machine learning patterns to recognize transaction anomalies. The prediction system should be balanced on reducing fraud as precisely as possible.",
          more: "General Finance synthetic data can be enhanced to create balanced datasets and perform predictive analysis of fraudulent transactions. Improved fraud detection model performance using machine learning and aggregated data can reduce fraud operating costs while saving consumers tens of millions of pounds.",
          image: "/images/use-case/Fraudulent-Activity-Identification.png",
          seeMore: false,
        },
        {
          title: "Bias Mitigation",
          description:
            "Synthetic data in the hiring process reduces reliance on limited historical data by supplementing it with high-quality augmented data to eliminate data gaps that may currently exist. With rich and balanced data, recruitment algorithms can make the talent search fairer and more diverse. Synthetic data can also be used to train algorithms to better identify skills that can be inferred from different roles and functions to better match and place talent in job opportunities.",
          more: "",
          image: "/images/use-case/Bias-Mitigation.png",
          seeMore: false,
        },
        {
          title: "Pattern Analysis and Trend Projections",
          description:
            "Businesses need to quickly understand consumer habits and behaviors to identify and sometimes influence consumers' purchasing decisions especially in e-commerce. Synthetic data can be used to improve data analytics and gain insight into these consumption patterns and future trends.",
          more: "Since synthetic data is not affected by traditional statistical problems, such as ignoring patterns and not responding, it can be used to accurately detect trends related to the behavior of client. Access to more information allows e-commerce businesses to precisely segment customer data and personalize marketing campaigns to successfully target specific customers. The increased effectiveness of promotional efforts can lead to higher customer satisfaction and improved profits.",
          image: "/images/use-case/Pattern-Analysis-and-Trend-Projections.png",
          seeMore: false,
        },
        {
          title: "Data Monetization",
          description:
            "Data monetization becomes an essential element of profitability. The use of sensitive customer data poses significant privacy and non-compliance risks to banking consumers in the event of a data leak. However, a bank's customer data  can be used to generate statistically similar, privacy-compliant, data-enhanced data products for sale to businesses across industries or for other banks.",
          more: "Although the data set is not  original, the similarity in statistical composition gives the buyer the same information as  the original data set. Synthetic data is preferred over anonymized data because collecting anonymous data limits the ability to glean insights and value from the data. Synthetic data for monetization can benefit data-sharing business partners  by allowing them to  better understand customer needs and improve product innovation insights.",
          image: "/images/use-case/Data-Monetization.png",
          seeMore: false,
        },
      ],
    };
  },
  methods: {
    seeMore(useCase) {
      this.useCases.map((use) => {
        if (use.title == useCase.title) {
          use.seeMore = !use.seeMore;
          console.log(use.title, useCase.title);
        } else {
          use.seeMore = false;
        }
        return use;
      });
    },
  },
};
</script>

<style lang="scss">
#cases {
  padding: 50px 0;

  .case {
    margin-bottom: 96px;

    @media (min-width: 991px) {
      &:nth-child(even) {
        .image {
          order: 2;
        }
        .content {
          order: 1;
        }
      }
    }

    .image {
      position: relative;
      z-index: 3;
      max-height: 349px;

      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: "";
        background: radial-gradient(ellipse, transparent 70%, white 30%);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 7px 5px;

      h5 {
        margin-bottom: 22px;
      }

      p {
        line-height: 181%;
        margin-bottom: 30px;
      }
    }

    .actions {
      .btn {
        background: #f3f4f6;
        border-radius: 8px;
        width: 130px;
        padding: 8px 25px;

        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #0d1183;
      }
    }
  }
}
</style>
